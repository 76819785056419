import { useState } from "react";
import useConstructor from "../../config/hooks/useConstructor";
import ContratController from "../../config/apiUtils/ContratController";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import { columns } from "../../components/Grids/Contrat/annexes";
import CloseButton from "../../components/Buttons/CloseButton";
import { RenderIf, createNotification, downloadFile } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import FicheAnnexe from "./FicheAnnexe";
import EditionController from "../../config/apiUtils/EditionController";
import PdfButton from "../../components/Buttons/PdfButton";
import AgGrid from "../../components/AgGrid";

export default function ListeAnnexes(props) {
  const [loading, setLoading] = useState(false);
  const [annexes, setAnnexes] = useState([]);
  const [annexeToShow, setAnnexeToShow] = useState(null);

  const loadAnnexes = async () => {
    setLoading(true);
    setAnnexes(await ContratController.getAnnexes(props.idcontrat));
    setLoading(false);
  };

  useConstructor(async () => {
    await loadAnnexes();
  });

  const onRowClick = (params) => {
    setAnnexeToShow(
      annexes.find((annexe) => annexe.idannexe === params.data.idannexe),
    );
  };

  const renderAnnexes = () => {
    return (
      <AgGrid
        columnDefs={columns}
        rowData={annexes}
        onRowClicked={onRowClick}
        height={400}
      />
    );
  };

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>
                Annexes du contrat :{" "}
                {props.contrat?.numerocontrat ?? props.contrat?.raisonsociale}
              </CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf isTrue={annexeToShow != null}>
                <PdfButton
                  color="primary"
                  style={{ marginRight: 30 }}
                  onClick={async () => {
                    createNotification(
                      "info",
                      "",
                      "Préparation du document en cours...",
                    );
                    const resDocument = await EditionController.getAnnexe(
                      annexeToShow.idannexe,
                    );
                    downloadFile(
                      resDocument,
                      `annexe_${props.contrat?.millesime}-${props.contrat?.numerocontrat}-${annexeToShow.numeroannexe}.pdf`,
                      "application/octet-stream",
                    );
                    createNotification(
                      "success",
                      "",
                      "Annexe générée avec succès.",
                    );
                  }}
                />
              </RenderIf>
              <CloseButton
                onClick={
                  annexeToShow == null
                    ? props.onClickClose
                    : async () => {
                        await loadAnnexes();
                        await props.loadContrats();
                        setAnnexeToShow(null);
                      }
                }
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <Collapse
                  isOpen={annexeToShow == null}
                  mountOnEnter
                  unmountOnExit
                >
                  {renderAnnexes()}
                </Collapse>
                <Collapse
                  isOpen={annexeToShow != null}
                  mountOnEnter
                  unmountOnExit
                >
                  <FicheAnnexe
                    annexe={annexeToShow}
                    idcontrat={props.idcontrat}
                    onClickClose={async () => {
                      await props.loadContrats();
                      await loadAnnexes();
                      setAnnexeToShow(null);
                    }}
                  />
                </Collapse>
              </RenderIf>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
