import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import CommuneController from "../config/apiUtils/CommuneController";
import { selectStyles } from "../config/styles";
import Select from "react-select";
import { RenderIf } from "../config/utils";
import { useDebouncedFunction } from "../config/hooks/useDebouncedFunction";

export default React.memo(function CommuneInput(props) {
  const [codePostal, setCodePostal] = useState(props.codepostal);
  const [communes, setCommunes] = useState([]);
  const communeSelecterRef = useRef();
  const [loading, setLoading] = useState(false);

  const loadCommunes = async (codepostal) => {
    if (codepostal == null) {
      return;
    }

    if (codepostal.toString().length >= 3) {
      setLoading(true);
      setCommunes([]);
      if (communeSelecterRef.current != null) {
        communeSelecterRef.current.clearValue();
      }
      const resCommunes = await CommuneController.getCommunesByCodePostal(
        codepostal,
      );
      setCommunes(resCommunes);
      const selectedCommune = resCommunes.find(
        (commune) => commune.idcommune === props.idcommune,
      );
      if (selectedCommune) {
        setCodePostal(selectedCommune.codepostal);
        communeSelecterRef.current.setValue({
          value: selectedCommune.idcommune,
          label: `${selectedCommune.codepostal} - ${selectedCommune.nomcommune}`,
        });
      }
      if (resCommunes.length === 1) {
        setCodePostal(resCommunes[0].codepostal);
      }
      setLoading(false);
    }
  };

  const loadCommunesByNomcommune = async (nomcommune) => {
    if (nomcommune.length >= 2) {
      setLoading(true);
      setCommunes([]);
      if (communeSelecterRef.current != null) {
        communeSelecterRef.current.clearValue();
      }
      const resCommunes = await CommuneController.getCommunesByNomcommune(
        nomcommune,
      );
      setCommunes(resCommunes);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.codepostal != null) {
      loadCommunes(props.codepostal);
    }
  }, [props.codepostal]);

  const handleChangeCodePostal = useCallback(async (event) => {
    setCodePostal(event.target.value);
    if (event.target.value.length >= 3) {
      await loadCommunes(event.target.value);
    } else if (event.target.value.length < 3) {
      setCommunes([]);
    }
  });

  const getOptions = useCallback(
    () =>
      communes.map((commune) => ({
        value: commune.idcommune,
        label: `${commune.codepostal} - ${commune.nomcommune}`,
      })),
    [communes],
  );

  const setCodePostalOnChange = useCallback(
    (selection) => {
      if (selection) {
        if (props.onSelect != null) {
          props.onSelect(selection.value);
        }
        setCodePostal(
          communes.find((c) => c.idcommune === selection.value)?.codepostal ??
            codePostal,
        );
      }
    },
    [setCodePostal, codePostal, communes],
  );

  // Fonction Debounced
  const debouncedLoadCommune = useDebouncedFunction(async (newValue) => {
    if (newValue.match(/^[0-9]+$/)) {
      await loadCommunes(newValue);
    } else if (newValue.match(/^[A-Za-z -éÉèÈàÀ]+$/)) {
      await loadCommunesByNomcommune(newValue);
    }
  }, 500);

  return (
    <Row>
      <RenderIf
        isTrue={
          !(
            props.hideCodePostalInput != null &&
            props.hideCodePostalInput == true
          )
        }
      >
        <Col md={4}>
          <FormGroup>
            <Label for="codepostal">Code postal</Label>
            <Input
              type="text"
              name="codepostal"
              placeholder="Code postal"
              maxLength="5"
              value={codePostal}
              disabled={props.disabled ?? false}
              required={props.required ?? false}
              onChange={handleChangeCodePostal}
            ></Input>
          </FormGroup>
        </Col>
      </RenderIf>
      <Col
        md={
          props.hideCodePostalInput != null && props.hideCodePostalInput == true
            ? 12
            : 8
        }
      >
        <FormGroup>
          <Label for="idcommune">{props.label ?? "Commune"}</Label>
          <Select
            isClearable
            closeMenuOnSelect
            className="select-single"
            classNamePrefix="react-select"
            autosize={false}
            menuPlacement={"auto"}
            menuPosition={"fixed"}
            menuPortalTarget={document.body}
            noOptionsMessage={() => "Pas de commune"}
            loadingMessage={() => "Chargement des communes..."}
            onChange={setCodePostalOnChange}
            onInputChange={debouncedLoadCommune}
            ref={communeSelecterRef}
            isLoading={loading}
            isDisabled={props.disabled ?? false}
            id={props.name ?? "idcommune"}
            name={props.name ?? "idcommune"}
            placeholder="Sélectionnez une commune..."
            styles={selectStyles}
            options={getOptions()}
          />
        </FormGroup>
      </Col>
    </Row>
  );
});
