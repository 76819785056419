import { Col, Row } from "reactstrap";
import "../assets/css/SpinLoadingAnimation.css";
import React from "react";

interface IStyle {
  width?: number;
  height?: number;
}

interface IProps {
  style?: IStyle;
}

export default function SpinLoadingAnimation(props: IProps) {
  return (
    <Row>
      <Col style={{ display: "flex", justifyContent: "center" }}>
        <div className="loader-wrapper" style={props.style}>
          <div className="loader">
            <div className="loader loader-inner"></div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
