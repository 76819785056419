import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import { columns } from "../../components/Grids/planproduction/listeparorganismestockeur";
import StoreContext from "../../context/StoreContext";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import PlanProductionController from "../../config/apiUtils/PlanProductionController";
import CloseButton from "../../components/Buttons/CloseButton";
import useConstructor from "../../config/hooks/useConstructor";
import {
  getQueryParam,
  setQueryParam,
  RenderIf,
  createNotification,
  downloadCsv,
  convertToCSV,
  unflattenList,
} from "../../config/utils";
import moment from "moment";
import CsvButton from "../../components/Buttons/CsvButton";
import AgGrid from "../../components/AgGrid";
import { IPlanProduction } from "../../config/types/planproduction";
import { IOrganismeStockeur } from "../../config/types/organismestockeur";
import { CellClickedEvent } from "ag-grid-community";

const mapKeysNames = {
  "millesime": "Année de récolte",
  "libelletypeproduction": "Type de production",
  "numerocontrat": "Contrat",
  "idrefvariete": "Code variété",
  "libellevariete": "Variété",
  "quantitesemence": "Quantité de semence",
  "quantitelivree": "Quantité livrée",
  "tonnage": "Tonnage de référence moyen",
  "tonnageestime": "Tonnage estimé de l'année",
  "nomsilo": "Silo de livraison",
  "nomsilodefinitif": "Silo définitif",
  "nomorganismestockeur": "Organisme stockeur",
  "nomparcelle": "Parcelle",
  "parcelle.codepostal": "Code postal parcelle",
  "parcelle.nomcommune": "Commmune parcelle",
  "surfaceengageeprintemps": "Surface engagée au printemps",
  "surfaceengageeautomne": "Surface engagée en automne",
  "surfaceprintemps": "Surface réelle printemps",
  "surfaceautomne": "Surface réelle automne",
  "surfacetotale": "Surface totale",
  "rendementbrut": "Rendement brut",
  "rendementnet": "Rendement net",
  "estconforme": "Conformité",
  "surveillancecolzasanve": "Surveillance (Colza/Sanve)",
  "libellestatutjuridique": "Statut juridique",
  "raisonsociale": "Exploitation",
  "siret": "Numéro SIRET",
  "adresse": "Adresse",
  "codepostal": "Code postal",
  "ville": "Commune",
  "codedc": "Code DC",
  "codeoctagri": "Code OCTAGRI",
  "numerounilever": "Numéro UNILEVER",
  "rendementrefmoyen": "Rendement de référence moyen",
  "rendementrefmillesime": "Rendement estimé de l'année",
  "zonesproduction": "Zones de production",
};

interface IProps {
  close: () => void;
}

export default function ListeParOrganismeStockeur(
  props: IProps,
): React.JSX.Element {
  const store = useContext(StoreContext);
  const [idOrganismeStockeur, setIdOrganismeStockeur] = useState<string>("-1");
  const [dataSource, setDataSource] = useState<IPlanProduction[]>(
    store.planProduction.planProductionParcelle,
  );
  const [organisme, setOrganisme] = useState<
    IOrganismeStockeur | null | undefined
  >(null);
  const [organismesStockeur, setOrganismesStockeur] = useState<
    IOrganismeStockeur[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [totalAutomne, setTotalAutomne] = useState(0);
  const [totalPrintemps, setTotalPrintemps] = useState(0);
  const [varietes, setVarietes] = useState<Record<string, number>>({});
  const [totalBio, setTotalBio] = useState(0);
  const [totalNonBio, setTotalNonBio] = useState(0);

  useConstructor(() => {
    const osTemp = [...store.organismesStockeur];
    const idos = getQueryParam("idos");

    if (getQueryParam("idos") && idos !== "" && idos !== null) {
      setIdOrganismeStockeur(idos);
    }
    if (osTemp.length === 1) {
      setIdOrganismeStockeur(osTemp[0].idorganismestockeur);
      setQueryParam("idos", osTemp[0].idorganismestockeur);
    }
    osTemp.unshift({
      idorganismestockeur: "-1",
      nomorganismestockeur: "--",
    });

    setOrganismesStockeur(osTemp);
  });

  const renderOrganismesStockeurOption = () => {
    return organismesStockeur.map((organisme, key) => {
      return (
        <option value={organisme.idorganismestockeur} key={key}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  useEffect(() => {
    const loadDataSource = () => {
      let totPrintemps: number = 0;
      let totAutomne: number = 0;
      let totBio: number = 0;
      let totNonBio: number = 0;
      const surfacesVarietes: Record<string, number> = {};
      dataSource.forEach((element) => {
        totPrintemps += element.surfaceprintemps ?? 0;
        totAutomne += element.surfaceautomne ?? 0;
        if (element.idreftypeproduction === 4) {
          totBio +=
            (element.surfaceprintemps ?? 0) + (element.surfaceautomne ?? 0);
        } else {
          totNonBio +=
            (element.surfaceprintemps ?? 0) + (element.surfaceautomne ?? 0);
        }
        if (element.libellevariete !== null) {
          if (
            Object.prototype.hasOwnProperty.call(
              surfacesVarietes,
              element.libellevariete,
            )
          ) {
            surfacesVarietes[element.libellevariete] +=
              (element.surfaceprintemps ?? 0) + (element.surfaceautomne ?? 0);
          } else {
            surfacesVarietes[element.libellevariete] =
              (element.surfaceprintemps ?? 0) + (element.surfaceautomne ?? 0);
          }
        }
      });
      setTotalPrintemps(parseFloat(totPrintemps.toFixed(2)));
      setTotalAutomne(parseFloat(totAutomne.toFixed(2)));
      setTotalBio(parseFloat(totBio.toFixed(2)));
      setTotalNonBio(parseFloat(totNonBio.toFixed(2)));
      setVarietes(surfacesVarietes);
    };

    setDataSource(store.planProduction.planProductionOrganismeStockeur);

    loadDataSource();
  }, [
    idOrganismeStockeur,
    dataSource,
    store.planProduction.planProductionOrganismeStockeur,
  ]);

  const changePlanProductionOrganisme = async (idorganismestockeur: string) => {
    setLoading(true);
    if (idOrganismeStockeur === "-1") {
      store.planProduction.setPlanProductionOrganismeStockeur([]);
    } else {
      setOrganisme(
        organismesStockeur.find(
          (organismestockeur) =>
            organismestockeur.idorganismestockeur ===
            parseInt(idOrganismeStockeur),
        ),
      );

      const resPlanProductionOrganismeStockeur =
        await PlanProductionController.getPlanProductionOrganismeStockeur(
          parseInt(idorganismestockeur),
          store.millesime.idmillesime,
        );
      store.planProduction.setPlanProductionOrganismeStockeur(
        resPlanProductionOrganismeStockeur,
      );
    }

    setLoading(false);
  };

  const onCellClick = (params: CellClickedEvent) => {
    navigator.clipboard.writeText(params.value);
    createNotification(
      "info",
      "Information",
      params.colDef.headerName + " copié dans le presse-papier",
      1000,
    );
  };

  useEffect(() => {
    changePlanProductionOrganisme(idOrganismeStockeur);
  }, [idOrganismeStockeur]);

  const renderLoading = () => {
    return <SpinLoadingAnimation />;
  };

  const renderSurfacesVarietes = () => {
    const libelles = Object.keys(varietes);
    return libelles.map((libellevariete, i) => {
      return (
        <Fragment key={i}>
          Total {libellevariete != "null" ? libellevariete : "sans variété"} :{" "}
          {varietes[libellevariete] !== null
            ? varietes[libellevariete].toFixed(2)
            : 0}{" "}
          Ha {i + 1 == libelles.length ? "" : "| "}
        </Fragment>
      );
    });
  };

  const renderFooter = () => {
    return (
      <RenderIf isTrue={idOrganismeStockeur !== "-1" && !loading}>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              fontWeight: "bold",
            }}
          >
            Total printemps : {totalPrintemps} Ha | Total automne :{" "}
            {totalAutomne} Ha | Total :{" "}
            {(totalAutomne + totalPrintemps).toFixed(2)} Ha
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              fontWeight: "bold",
            }}
          >
            Total bio : {totalBio} Ha | Total non bio : {totalNonBio} Ha
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              fontWeight: "bold",
              marginTop: 10,
            }}
          >
            {renderSurfacesVarietes()}
          </div>
        </>
      </RenderIf>
    );
  };

  const renderGridOrganisme = () => {
    return (
      <Row>
        <h4>
          <b>
            {idOrganismeStockeur !== "-1"
              ? organisme?.nomorganismestockeur
              : "Veuillez sélectionner un organisme stockeur"}
          </b>
        </h4>
        {loading && renderLoading()}
        {idOrganismeStockeur !== "-1" && !loading && (
          <Col md="12">
            <AgGrid
              columnDefs={columns}
              rowData={unflattenList(dataSource)}
              showFilterLine
              height={700}
              onCellClicked={onCellClick}
            />
          </Col>
        )}
      </Row>
    );
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>
              Plan de production par organisme stockeur
            </CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={dataSource.length > 0}>
              <CsvButton
                color="primary"
                style={{ marginRight: 30 }}
                onClick={() => {
                  downloadCsv(
                    convertToCSV(dataSource, ";", mapKeysNames),
                    "plan_prod_os_" +
                      store.millesime.idmillesime +
                      "_" +
                      moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
            <CloseButton onClick={props.close} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="4">
            <Label for="idorganismestockeur">
              Sélectionnez l'organisme stockeur
            </Label>
            <Input
              type="select"
              className="select-input"
              name="idorganismestockeur"
              value={idOrganismeStockeur}
              onChange={(event) => {
                setQueryParam("idos", event.target.value);
                setIdOrganismeStockeur(event.target.value);
              }}
            >
              {renderOrganismesStockeurOption()}
            </Input>
          </Col>
        </Row>
        <hr />
        {renderGridOrganisme()}
        {renderFooter()}
      </CardBody>
    </Card>
  );
}
