import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Type de formation",
    field: "libelletypeformation",
    minWidth: 170,
  },
  {
    headerName: "Millésime",
    field: "millesime",
    minWidth: 170,
  },
  {
    headerName: "Date de formation",
    field: "dateformation",
    minWidth: 170,
  },
  {
    headerName: "Formation suivie",
    field: "suivi",
    minWidth: 170,
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) => {
      return cellRendererSwitch(params.value, cellRendererDefaultBoolean);
    },
  },
  {
    headerName: "Commentaire",
    field: "commentaire",
    minWidth: 350,
  },
];
