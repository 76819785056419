import { ReactComponent as AdminIcon } from "../assets/img/sidebar/admin.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as CheckListIcon } from "../assets/img/sidebar/check-list.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as ContractIcon } from "../assets/img/sidebar/contract.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as CultureIcon } from "../assets/img/sidebar/culture.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as FarmIcon } from "../assets/img/sidebar/farm.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as FarmerIcon } from "../assets/img/sidebar/farmer.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as MapIcon } from "../assets/img/sidebar/map.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as MoneyIcon } from "../assets/img/icons/money.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as MustardIcon } from "../assets/img/sidebar/mustard.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as SelectionMapIcon } from "../assets/img/sidebar/selection-map.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as TreeCityIcon } from "../assets/img/sidebar/tree-city.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as UserIcon } from "../assets/img/sidebar/user.svg";

const icons = {
  AdminIcon,
  CheckListIcon,
  ContractIcon,
  CultureIcon,
  FarmIcon,
  FarmerIcon,
  MapIcon,
  MoneyIcon,
  MustardIcon,
  SelectionMapIcon,
  TreeCityIcon,
  UserIcon,
};

export default icons;
