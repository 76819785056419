import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import { useContext, useState } from "react";
import StoreContext from "../../context/StoreContext";
import {
  GoToTopPage,
  RenderIf,
  createNotification,
  getQueryParam,
} from "../../config/utils";
import ContratController from "../../config/apiUtils/ContratController";
import ParcelleController from "../../config/apiUtils/ParcelleController";
import useConstructor from "../../config/hooks/useConstructor";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import CommuneInput from "../../components/CommuneInput";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import CancelButton from "../../components/Buttons/CancelButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import { columns } from "../../components/Grids/Contrat/tableparcelleformcompleter";
import { columns as columnsParcelleContrat } from "../../components/Grids/Contrat/tableparcellerecapformcompleter";
import AgGrid from "../../components/AgGrid";

export default function FormNewAnnexe(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [contrat, setContrat] = useState({});
  const [exploitation, setExploitation] = useState({});
  const [changementAdresse, setChangementAdresse] = useState(false);
  const [modificationsSurfaces, setModificationsSurfaces] = useState(false);
  const [changementStatutJuridique, setChangementStatutJuridique] =
    useState(false);
  const [perteLabel, setPerteLabel] = useState(false);
  const [perteRecolte, setPerteRecolte] = useState(false);
  const [suppressionParcelles, setSuppressionParcelles] = useState(false);
  const [ajoutParcelles, setAjoutParcelles] = useState(false);
  const [modificationParcelles, setModificationParcelles] = useState(false);
  const [parcelleSelected, setParcelleSelected] = useState(null);

  useConstructor(async () => {
    GoToTopPage();
    setLoading(true);
    const tmp = await ContratController.getContratDetail(getQueryParam("id"));
    setContrat(tmp);
    const tmpExploitation =
      await ExploitationController.getExploitationByIdexploitation(
        tmp.idexploitation,
      );
    setExploitation(tmpExploitation);
    store.referentiels.setStatutsJuridique(
      await ReferentielController.getStatutsJuridique(),
    );
    const res = await ParcelleController.getParcelles();
    store.setParcelles(res);
    const typesSol = [];
    res.forEach((p) => {
      if (!typesSol.some((type) => type === p.libelletypesol)) {
        typesSol.push(p.libelletypesol);
      }
    });
    columns.forEach((column) => {
      if (column.name === "libelletypesol") {
        column.filterEditorProps = {
          multiple: true,
          wrapMultiple: false,
          dataSource: typesSol.map((c) => {
            return { id: c, label: c };
          }),
        };
      }
    });
    columnsParcelleContrat.find(
      (c) => c.name === "nomparcelle",
    ).editable = true;
    columnsParcelleContrat.find(
      (c) => c.name === "surfaceengageeprintemps",
    ).hide = true;
    columnsParcelleContrat.find(
      (c) => c.name === "surfaceengageeautomne",
    ).hide = true;
    columnsParcelleContrat.find((c) => c.name === "surfaceengagee").hide = true;
    setLoading(false);
  });

  const renderStatutsJuridiqueOptions = () => {
    return store.referentiels.statutsJuridique.map((statut, key) => {
      return (
        <option value={statut.idstatutjuridique} key={key}>
          {statut.libellestatut}
        </option>
      );
    });
  };

  const renderParcelleAjouteeInformation = () => {
    return (
      <Table style={{ color: "#FFFFFF" }}>
        <tbody>
          <tr>
            <th scope="row">Nom de la parcelle</th>
            <td>{parcelleSelected?.nomparcelle}</td>
          </tr>
          <tr>
            <th scope="row">Commune</th>
            <td>
              {parcelleSelected?.codepostal} {parcelleSelected?.nomcommune}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderParcelleSuppression = () => {
    return contrat?.parcelles
      ?.filter((p) => !p.ajoutee)
      .map((parcelle, key) => (
        <tr key={key} style={{ cursor: "pointer" }}>
          <th scope="row">
            <Input
              type="checkbox"
              onClick={(event) => {
                const tmp = { ...contrat };
                tmp.parcelles.find(
                  (p) => p.idparcelle === parcelle.idparcelle,
                ).supprimee = event.target.checked;
                setContrat(tmp);
              }}
            />
          </th>
          <td>{parcelle.nomparcelle}</td>
          <td>{parcelle.codepostal + " " + parcelle.nomcommune}</td>
          <td>
            {parcelle.surfaceengageeautomne +
              " Ha automne - " +
              parcelle.surfaceengageeprintemps +
              " Ha printemps"}
          </td>
        </tr>
      ));
  };

  const renderParcellesAjoutees = () => {
    return contrat?.parcelles
      ?.filter((p) => p.ajoutee)
      .map((parcelle, key) => (
        <tr key={key} style={{ cursor: "pointer" }}>
          <th scope="row">
            <Input
              type="checkbox"
              defaultChecked={parcelle.ajoutee}
              onClick={(event) => {
                const tmp = { ...contrat };
                tmp.parcelles.find(
                  (p) => p.idparcelle === parcelle.idparcelle,
                ).ajoutee = event.target.checked;
                setContrat(tmp);
              }}
            />
          </th>
          <td>{parcelle.nomparcelle}</td>
          <td>{parcelle.codepostal + " " + parcelle.nomcommune}</td>
          <td>
            {parcelle.surfaceautomne +
              " Ha automne - " +
              parcelle.surfaceprintemps +
              " Ha printemps"}
          </td>
        </tr>
      ));
  };

  const onRowClickAddParcelle = (params) => {
    const tmp = { ...params.data };
    tmp.ajoutee = true;
    tmp.commentaire = null;
    tmp.surfaceengageeautomne = 0;
    tmp.surfaceengageeprintemps = 0;
    tmp.surfaceautomne = 0;
    tmp.surfaceprintemps = 0;
    setParcelleSelected(tmp);
  };

  const onEditParcelleModifieeComplete = ({ value, columnId, data }) => {
    const tmp = { ...contrat };
    if (columnId === "nomparcelle") {
      tmp.parcelles.find((p) => p.idparcelle === data.idparcelle)[
        "old" + columnId
      ] = value;
      tmp.parcelles.find((p) => p.idparcelle === data.idparcelle)[columnId] =
        value;
      tmp.parcelles.find(
        (p) => p.idparcelle === data.idparcelle,
      ).modifiee = true;
    } else {
      tmp.parcelles.find((p) => p.idparcelle === data.idparcelle)[
        "old" + columnId
      ] = value;
      tmp.parcelles.find((p) => p.idparcelle === data.idparcelle)[columnId] =
        parseFloat(value);
      tmp.parcelles.find(
        (p) => p.idparcelle === data.idparcelle,
      ).modifiee = true;
    }
    setContrat(tmp);
  };

  const onSubmitNewAnnexe = async (event) => {
    event.preventDefault();
    if (
      !changementAdresse &&
      !changementStatutJuridique &&
      !perteLabel &&
      !perteRecolte &&
      !suppressionParcelles &&
      !ajoutParcelles &&
      !modificationParcelles
    ) {
      createNotification(
        "warning",
        "Attention",
        "Aucune modification n'a été renseignée sur cette annexe. Merci de renseigner au moins une modification afin de continuer la saisie de l'annexe.",
      );
      return;
    }
    if (
      changementStatutJuridique &&
      event.target.newsiret.value === exploitation.siret
    ) {
      createNotification(
        "error",
        "Erreur sur le SIRET",
        "Merci de renseigner un numéro de SIRET différent du numéro de SIRET d'origine",
      );
      return;
    }

    const tmp = { ...contrat };
    const parcelles = tmp.parcelles.filter(
      (p) => p.ajoutee || p.modifiee || p.supprimee,
    );

    if (ajoutParcelles) {
      let error = false;
      parcelles.forEach((parcelle) => {
        if (
          parcelle.ajoutee &&
          (parcelle.surfaceprintemps == null || parcelle.surfaceautomne == null)
        ) {
          createNotification(
            "error",
            "Erreur",
            "Au moins une de vos parcelles ajoutées à une surface non renseignée. Merci de renseigner une surface pour le printemps et pour l'hiver pour continuer la saisie.",
          );
          error = true;
        }
      });
      if (error) {
        return;
      }
    }

    const annexe = {
      changementadresse: changementAdresse,
      newadresse: event.target.adresse.value,
      newidcommune: event.target.idcommune?.value,
      changementstatut: changementStatutJuridique,
      newidstatutjuridique: event.target.idstatutjuridique.value,
      newsiret: event.target.newsiret.value,
      pertelabel: perteLabel,
      descriptionpertelabel: event.target.descriptionpertelabel.value,
      perterecolte: perteRecolte,
      descriptionperterecolte: event.target.descriptionperterecolte.value,
      suppressionparcelles: suppressionParcelles,
      ajoutparcelles: ajoutParcelles,
      modificationparcelles: modificationParcelles,
      parcelles: parcelles,
    };

    const res = await ContratController.addNewAnnexe(contrat.idcontrat, annexe);

    if (res.status === 200) {
      createNotification(
        "success",
        "Annexe " + res.data.numeroannexe + " créé avec succès",
      );
      createNotification(
        "warning",
        "Attention, votre annexe doit d'abord être validée par l'APGMB pour que les changements relatifs soient effectifs.",
        null,
        10000,
      );
      props.onClickClose();
    }
  };

  const renderStatutsJuridique = () => (
    <>
      <Label check style={{ display: "flex" }}>
        <Input
          type="checkbox"
          onClick={(event) => {
            setChangementStatutJuridique(event.target.checked);
          }}
        />
        &nbsp;<h5>Changement de statut juridique</h5>
      </Label>
      <Label for="idstatutjuridique">Nouveau statut juridique</Label>
      <Input
        type="select"
        className="select-input"
        name="idstatutjuridique"
        defaultValue={exploitation.idstatutjuridique}
        style={{ marginBottom: 10 }}
        disabled={!changementStatutJuridique}
      >
        {renderStatutsJuridiqueOptions()}
      </Input>
      <Label for="newsiret">Nouveau numéro SIRET</Label>
      <Input
        type="text"
        name="newsiret"
        defaultValue={exploitation.siret}
        disabled={!changementStatutJuridique}
        required
      />
    </>
  );

  const renderPerteLabel = () => (
    <>
      <Label check style={{ display: "flex", marginTop: 10 }}>
        <Input
          type="checkbox"
          onClick={(event) => {
            setPerteLabel(event.target.checked);
          }}
        />
        &nbsp;<h5>Perte du Label</h5>
      </Label>
      <Label for="descriptionpertelabel">Description </Label>
      <Input
        type="textarea"
        name="descriptionpertelabel"
        disabled={!perteLabel}
        placeholder="Commentaire et remarques..."
      />
    </>
  );

  const renderPerteRecolte = () => (
    <>
      <Label check style={{ display: "flex", marginTop: 10 }}>
        <Input
          type="checkbox"
          onClick={(event) => {
            setPerteRecolte(event.target.checked);
          }}
        />
        &nbsp;<h5>Perte de récolte</h5>
      </Label>
      <Label for="descriptionperterecolte">Description </Label>
      <Input
        type="textarea"
        name="descriptionperterecolte"
        disabled={!perteRecolte}
        placeholder="Commentaire et remarques..."
      />
    </>
  );

  const renderAdresse = () => (
    <>
      <Label check style={{ display: "flex" }}>
        <Input
          type="checkbox"
          onClick={(event) => {
            setChangementAdresse(event.target.checked);
          }}
        />
        &nbsp;<h5>Changement d'adresse</h5>
      </Label>
      <Label for="adresse">Nouvelle adresse</Label>
      <Input
        type="text"
        name="adresse"
        style={{ marginBottom: 10 }}
        placeholder="Nouvelle adresse"
        defaultValue={contrat.adresse}
        disabled={!changementAdresse}
      />
      <CommuneInput
        codepostal={exploitation.codepostal}
        idcommune={exploitation.idcommune}
        setIdCommune={() => {}}
        disabled={!changementAdresse}
        name={"idcommune"}
      />
    </>
  );

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag="h5">
                Ajouter une annexe au contrat {contrat.numerocontrat}
              </CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <CloseButton onClick={props.onClickClose} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Form onSubmit={onSubmitNewAnnexe}>
              <Row>
                <h3>Changement administratif</h3>
                <Col>
                  <Row>
                    <Col md="6">{renderStatutsJuridique()}</Col>
                    <Col md="6">{renderAdresse()}</Col>
                  </Row>
                  <Row>
                    <Col md="6">{renderPerteLabel()}</Col>
                    <Col md="6">{renderPerteRecolte()}</Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Label check style={{ display: "flex", marginTop: 10 }}>
                  <Input
                    type="checkbox"
                    onClick={(event) => {
                      setModificationsSurfaces(event.target.checked);
                    }}
                  />
                  &nbsp;<h5>Modification de surfaces</h5>
                </Label>
                <Collapse isOpen={modificationsSurfaces}>
                  <Col>
                    <h3>Modification de surfaces</h3>
                    <Label check style={{ display: "flex", marginBottom: 10 }}>
                      <Input
                        type="checkbox"
                        style={{ marginBottom: 20 }}
                        onClick={(event) => {
                          setSuppressionParcelles(event.target.checked);
                          contrat.parcelles.forEach(
                            (p) => (p.supprimee = false),
                          );
                        }}
                      />
                      &nbsp;<h5>Suppression de parcelles</h5>
                    </Label>
                    <RenderIf isTrue={suppressionParcelles}>
                      <h5>
                        Parcelles - Sélectionnez les parcelles à supprimer du
                        contrat
                      </h5>
                      <Table
                        striped
                        style={{
                          color: "#ffffff",
                          border: "1px solid",
                        }}
                      >
                        <tbody>{renderParcelleSuppression()}</tbody>
                      </Table>
                    </RenderIf>
                    <Label check style={{ display: "flex", marginBottom: 10 }}>
                      <Input
                        type="checkbox"
                        style={{ marginBottom: 10 }}
                        onClick={(event) => {
                          setAjoutParcelles(event.target.checked);
                          if (!event.target.checked) {
                            contrat.parcelles = contrat.parcelles.filter(
                              (p) => !p.ajoutee,
                            );
                          }
                          contrat.parcelles.forEach((p) => (p.ajoutee = false));
                        }}
                      />
                      &nbsp;<h5>Ajout de parcelles</h5>
                    </Label>
                    <RenderIf isTrue={ajoutParcelles}>
                      <h5>
                        Parcelles -{" "}
                        {parcelleSelected != null
                          ? parcelleSelected.nomparcelle
                          : "Sélectionnez une parcelle dans la liste à ajouter au contrat"}
                      </h5>
                      <Collapse isOpen={parcelleSelected == null}>
                        <AgGrid
                          columnDefs={columns}
                          rowData={store.parcelles}
                          height={400}
                          onRowClicked={onRowClickAddParcelle}
                          showFilterLine
                        />
                      </Collapse>
                      <Collapse
                        isOpen={parcelleSelected != null}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Row style={{ marginBottom: 10 }}>
                          <Col>{renderParcelleAjouteeInformation()}</Col>
                          <Col>
                            <Label for="commentaire">
                              Commentaires et remarques
                            </Label>
                            <Input
                              name="commentaire"
                              type="textarea"
                              placeholder="Commentaires et remarques..."
                              onChange={(e) =>
                                (parcelleSelected.commentaire = e.target.value)
                              }
                            ></Input>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: 10 }}>
                          <Col>
                            <Label for="surfaceautomne">
                              Surface engagée pour l'automne
                            </Label>
                            <Input
                              name="surfaceautomne"
                              type="text"
                              placeholder="Surface engagée pour l'automne"
                              pattern="^\d{1,4}(?:\.\d{1,2})?$"
                              title="Merci de ne saisir que des chiffres"
                              required
                              defaultValue={0}
                              onChange={(e) =>
                                (parcelleSelected.surfaceautomne = parseFloat(
                                  e.target.value,
                                ))
                              }
                            ></Input>
                          </Col>
                          <Col>
                            <Label for="surfaceprintemps">
                              Surface engagée pour le printemps
                            </Label>
                            <Input
                              name="surfaceprintemps"
                              type="text"
                              placeholder="Surface engagée pour le printemps"
                              pattern="^\d{1,4}(?:\.\d{1,2})?$"
                              title="Merci de ne saisir que des chiffres"
                              required
                              defaultValue={0}
                              onChange={(e) =>
                                (parcelleSelected.surfaceprintemps = parseFloat(
                                  e.target.value,
                                ))
                              }
                            ></Input>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: 10 }}>
                          <Col>
                            <Button
                              autoFocus={true}
                              color="primary"
                              style={{ marginRight: 10 }}
                              onClick={() => {
                                if (
                                  parcelleSelected.surfaceautomne == null ||
                                  parcelleSelected.surfaceprintemps == null ||
                                  isNaN(parcelleSelected.surfaceautomne) ||
                                  isNaN(parcelleSelected.surfaceprintemps)
                                ) {
                                  createNotification(
                                    "error",
                                    "Erreur",
                                    "Au moins une de vos parcelles ajoutées à une surface non renseignée. Merci de renseigner une surface pour le printemps et pour l'hiver pour continuer la saisie.",
                                  );
                                  return;
                                }
                                if (
                                  parcelleSelected.surfaceautomne == null ||
                                  isNaN(parcelleSelected.surfaceautomne)
                                ) {
                                  parcelleSelected.surfaceautomne = 0;
                                }
                                if (
                                  parcelleSelected.surfaceprintemps == null ||
                                  isNaN(parcelleSelected.surfaceprintemps)
                                ) {
                                  parcelleSelected.surfaceprintemps = 0;
                                }
                                const tmp = { ...contrat };
                                tmp.parcelles = tmp.parcelles.filter(
                                  (p) =>
                                    p.idparcelle !==
                                    parcelleSelected.idparcelle,
                                );
                                tmp.parcelles.push(parcelleSelected);

                                setContrat(tmp);
                                setParcelleSelected(null);
                              }}
                            >
                              Valider
                            </Button>
                            <CancelButton
                              style={{ marginRight: 10 }}
                              onClick={() => setParcelleSelected(null)}
                            />
                          </Col>
                        </Row>
                      </Collapse>
                      <RenderIf
                        isTrue={
                          contrat?.parcelles?.filter((p) => p.ajoutee).length >
                          0
                        }
                      >
                        <h6 style={{ marginTop: 10 }}>Parcelles ajoutées</h6>
                        <Table
                          striped
                          style={{
                            color: "#ffffff",
                            border: "1px solid",
                          }}
                        >
                          <tbody>{renderParcellesAjoutees()}</tbody>
                        </Table>
                      </RenderIf>
                    </RenderIf>
                    <Label
                      check
                      style={{
                        display: "flex",
                        marginTop: 20,
                        marginBottom: 10,
                      }}
                    >
                      <Input
                        type="checkbox"
                        style={{ marginBottom: 25 }}
                        onClick={(event) => {
                          setModificationParcelles(event.target.checked);
                        }}
                      />
                      &nbsp;<h5>Modification de parcelles</h5>
                    </Label>
                    <RenderIf isTrue={modificationParcelles}>
                      <h5>
                        Parcelles - Dans le tableau, double-cliquez sur
                        l'information à modifier
                      </h5>
                      <AgGrid
                        columnDefs={columnsParcelleContrat}
                        onCellEditingStopped={onEditParcelleModifieeComplete}
                        rowData={contrat?.parcelles?.filter(
                          (p) => !p.supprimee && !p.ajoutee,
                        )}
                        height={350}
                        showFilterLine
                      />
                    </RenderIf>
                  </Col>
                </Collapse>
              </Row>
              <Row>
                <Label check style={{ display: "flex", marginTop: 30 }}>
                  <Input
                    type="checkbox"
                    style={{ marginBottom: 20 }}
                    required
                  />
                  &nbsp;J'atteste sur l'honneur l'exactitude des informations
                  renseignées dans ce formulaire, et signe cette annexe au
                  contrat {contrat.numerocontrat}
                </Label>
              </Row>
              <Row>
                <Col md="6">
                  <ValidateButton style={{ marginTop: 5, marginRight: 15 }} />
                  <CancelButton
                    style={{ marginTop: 15 }}
                    onClick={(event) => {
                      event.preventDefault();
                      props.onClickClose();
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
}
