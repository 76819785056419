import axios from "axios";

class API {
  static url = window.env.API_URI;
  static codesNeedLogout = [400, 401, 402, 403, 404, 424, 429];

  constructor() {
    this.headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    console.log("window.env", window.env);
    console.log("API.url", API.url);
  }

  createAPIInstance() {
    this.setHeaderLocation();
    this.api = axios.create({
      baseURL: API.url,
      headers: this.headers,
    });

    this.api.defaults.timeout = 60000;
  }

  createAPI() {
    this.createAPIInstance();
    localStorage.setItem("needToReload", "false");
  }

  setHeaderContentType(contentType) {
    this.headers["Content-Type"] = contentType;
    this.createAPIInstance();
  }

  hasHeaderAuthentication() {
    return this.headers.Authorization && this.headers["WWW-Authenticate"];
  }

  addHeaderAuthentication() {
    if (this.hasHeaderAuthentication()) {
      this.deleteHeaderAuthentication();
    }
    this.headers["WWW-Authenticate"] = "Bearer";
    this.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("user")).access_token
    }`;
  }

  setHeaderLocation() {
    this.headers.frompage = window.location.pathname + window.location.search;
  }

  deleteHeaderAuthentication() {
    delete this.headers["WWW-Authenticate"];
    delete this.headers.Authorization;
  }

  isCodeNeedsLogout(status) {
    return API.codesNeedLogout.includes(status);
  }

  get(url, header) {
    return this.api.get(url, header);
  }

  post(url, data, header) {
    return this.api.post(url, data, header);
  }

  put(url, data, header) {
    return this.api.put(url, data, header);
  }

  delete(url) {
    return this.api.delete(url);
  }
}

export default API;
