// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Droid Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

.close {
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  position: absolute;
  color: inherit;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  font-weight: 700;
  font-size: 1.5rem;
  float: right;
  opacity: 0.5;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;uCAEqC;EACrC,mCAAmC;EACnC,kCAAkC;EAClC,cAAc;AAChB;;AAEA;EACE,MAAM;EACN,QAAQ;EACR,wBAAwB;EACxB,kBAAkB;EAClB,cAAc;EACd,6BAA6B;EAC7B,SAAS;EACT,wBAAwB;EACxB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,yBAAyB;AAC3B","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Droid Sans\", -apple-system, BlinkMacSystemFont, \"Helvetica Neue\",\n    \"Roboto\", \"Segoe UI\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\",\n    \"Droid Sans\", sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  color: #ffffff;\n}\n\n.close {\n  top: 0;\n  right: 0;\n  padding: 0.75rem 1.25rem;\n  position: absolute;\n  color: inherit;\n  background-color: transparent;\n  border: 0;\n  -webkit-appearance: none;\n  font-weight: 700;\n  font-size: 1.5rem;\n  float: right;\n  opacity: 0.5;\n  line-height: 1;\n  text-shadow: 0 1px 0 #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
