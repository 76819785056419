import React from "react";
// @ts-ignore
import Hashes from "jshashes";
import security from "../assets/img/icons/security.svg";
import bcrypt from "bcryptjs";
import { Bounce, toast } from "react-toastify";

export const createNotification = (
  type: string,
  title: string | null = null,
  message: string | null = null,
  timeout = 6000,
) => {
  const options = {
    autoClose: timeout,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
    style: {
      width: "fit-content",
    },
  };

  const content = (
    <div>
      {title && <h5>{title}</h5>}
      {message && <p style={{ marginBottom: 0 }}>{message}</p>}
    </div>
  );

  switch (type) {
    case "info":
      toast.info(content, options);
      break;
    case "success":
      toast.success(content, options);
      break;
    case "warning":
      toast.warning(content, options);
      break;
    case "error":
      toast.error(content, options);
      break;
  }
};

export const checkTelephone = (telephone: string) => {
  const regex =
    /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
  return regex.test(telephone);
};

export const checkMail = (mail: string) => {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex.test(mail);
};

export const getTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000);
};

export const getSha256 = (str: string) => {
  return new Hashes.SHA256().hex(str);
};

export const hash = (str: string) => {
  return bcrypt.hashSync(str);
};

export const checkPassword = (password: string, hash: string) => {
  return bcrypt.compareSync(password, hash);
};

export const GoToTopPage = () => {
  window.scrollTo(0, 0);
};

export const generateRandomString = (
  length: number = 10,
  characters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
) => {
  let result = "";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const focus = (id: string) => {
  const el = document.getElementById(id);
  if (el !== null) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }
};

export const RenderIf = ({
  children,
  isTrue,
}: {
  children: React.JSX.Element;
  isTrue: boolean;
}): React.JSX.Element | null => {
  return isTrue ? children : null;
};

const traverseAndFlatten = (
  currentNode: any,
  target: any,
  flattenedKey: string | null | undefined,
) => {
  for (const key in currentNode) {
    if (Object.prototype.hasOwnProperty.call(currentNode, key)) {
      let newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = flattenedKey + "." + key;
      }

      const value = currentNode[key];
      if (typeof value === "object") {
        traverseAndFlatten(value, target, newKey);
      } else {
        target[newKey] = value;
      }
    }
  }
};

export const flatten = (obj: any) => {
  const flattenedObject = {};
  traverseAndFlatten(obj, flattenedObject, undefined);
  return flattenedObject;
};

export const flattenList = (arr: any) => {
  if (arr === null || arr === undefined) {
    return [];
  }
  return arr.map((d: any) => flatten(d));
};

export const unflatten = (obj: any): any =>
  Object.keys(obj).reduce((res, k) => {
    k.split(".").reduce(
      (acc, e, i, keys) =>
        // @ts-ignore
        acc[e] ||
        // @ts-ignore
        (acc[e] = isNaN(Number(keys[i + 1]))
          ? keys.length - 1 === i
            ? obj[k]
            : {}
          : []),
      res,
    );
    return res;
  }, {});

export const unflattenList = (arr: any) => {
  if (arr === null || arr === undefined) {
    return [];
  }
  return arr.map((d: any) => unflatten(d));
};

export const Mailto = ({
  email,
  subject,
  body,
  style,
  children,
}: {
  email: string;
  subject: string;
  body?: any;
  style: any;
  children: any;
}) => {
  return (
    <a
      style={style}
      href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
    >
      <img alt="security" src={security} width={14} height={14} /> {children}
    </a>
  );
};

export const replaceKeys = (data: any, mapKeysNames: any) => {
  data.forEach(() => {
    Object.keys(mapKeysNames).forEach((key) => {
      data[mapKeysNames[key]] = data[key];
      delete data[key];
    });
  });
  return data;
};

export const convertToCSV = (
  objArray: any,
  delimiter = ",",
  mapKeysNames: any = null,
) => {
  if (objArray == null || objArray.length == 0) {
    return null;
  }

  let header: any = [];
  if (mapKeysNames === null) {
    for (const obj of objArray) {
      header = header.concat(Object.keys(obj));
    }
  } else {
    header = header.concat(Object.values(mapKeysNames));
  }

  header = [...new Set(header)];
  const headerString = header.join(delimiter);

  const replacer = (key: any, value: any) => {
    if (value === true || value === false) {
      return value ? "Oui" : "Non";
    }
    return value ?? "";
  };

  const rowItems =
    mapKeysNames === null
      ? objArray.map((row: any) =>
          header
            .map((fieldName: any) => JSON.stringify(row[fieldName], replacer))
            .join(delimiter),
        )
      : replaceKeys(objArray, mapKeysNames).map((row: any) =>
          Object.keys(mapKeysNames)
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(delimiter),
        );

  const csv = [headerString, ...rowItems].join("\r\n");
  return csv;
};

export const downloadCsv = (csv: any, name: any) => {
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", `${name}.csv`);
  a.click();
};

export const downloadFile = (
  content: any,
  filename: string,
  contentType: string,
) => {
  if (!contentType) contentType = "application/octet-stream";
  const a = document.createElement("a");
  const blob = new Blob([content], { type: contentType });
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.click();
  a.remove();
};

export const getQueryParam = (item: string) => {
  return new URLSearchParams(window.location.search).get(item);
};

export const getQueryParams = () => {
  return new URLSearchParams(window.location.search).toString();
};

export const setQueryParam = (item: string, value: string) => {
  const url = new URLSearchParams(window.location.search);
  url.set(item, value);
  // @ts-ignore
  window.history.replaceState(null, null, `?${url.toString()}`);
};

export const deleteAllQueryParams = () => {
  // @ts-ignore
  window.history.replaceState(null, null, window.location.pathname);
};

export const deleteQueryParam = (item: string) => {
  const url = new URLSearchParams(window.location.search);
  url.delete(item);
  // @ts-ignore
  window.history.replaceState(null, null, `?${url.toString()}`);
};
