import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/carto.css";
import AuthService from "./config/AuthService";
import StoreProvider from "./context/StoreProvider";
import LoadingPage from "./views/LoadingPage";
import MaintenanceLayout from "./layouts/Maintenance/MaintenanceLayout";
import useConstructor from "./config/hooks/useConstructor";
import { IInfo } from "./config/types/info";
import { AxiosResponse } from "axios";

const UserLayout = lazy(() => import("./layouts/User/UserLayout"));
const LoginLayout = lazy(() => import("./layouts/Login/LoginLayout"));

interface IEnv {
  API_URI: string;
  BUILD_VERSION: string;
  ENVIRONMENT: string;
  MESSAGE_MAINTENANCE_TITLE: string;
  MESSAGE_MAINTENANCE: string[];
  SHOW_MESSAGE_MAINTENANCE: boolean;
}

declare global {
  interface Window {
    env: IEnv;
  }
}

const loadWindowEnv = async () => {
  const res: AxiosResponse<IInfo> = await AuthService.getInfosServer();
  window.env.ENVIRONMENT = res.data.environment;
  window.env.MESSAGE_MAINTENANCE = res.data.messagemaintenance;
  window.env.MESSAGE_MAINTENANCE_TITLE = res.data.messagemaintenancetitle;
  window.env.SHOW_MESSAGE_MAINTENANCE = res.data.showmessagemaintenance;
};

loadWindowEnv();

export default function App(): React.JSX.Element {
  const [force, setForce] = useState(false);
  useConstructor(async () => {
    await loadWindowEnv();
    setForce(!force);
  });

  console.log(window.env);
  return (
    <Suspense fallback={<LoadingPage />}>
      <div
        className="App"
        style={{
          background:
            "linear-gradient(45deg, rgba(35,47,52,1) 0%, rgba(48,63,69,1) 22%, rgba(35,47,52,1) 100%)",
          backgroundColor: "#232F34",
          color: "#FFFFFF !important",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          overflowX: "auto",
        }}
      >
        <BrowserRouter>
          {!AuthService.isLoggedIn() &&
            !(
              window.env.SHOW_MESSAGE_MAINTENANCE &&
              !!window.env.MESSAGE_MAINTENANCE &&
              window.env.MESSAGE_MAINTENANCE.length > 0
            ) && <LoginLayout />}
          {AuthService.isLoggedIn() &&
            !(
              window.env.SHOW_MESSAGE_MAINTENANCE &&
              !!window.env.MESSAGE_MAINTENANCE &&
              window.env.MESSAGE_MAINTENANCE.length > 0
            ) && (
              <StoreProvider>
                <UserLayout />
              </StoreProvider>
            )}
          {window.env.SHOW_MESSAGE_MAINTENANCE &&
            !!window.env.MESSAGE_MAINTENANCE &&
            window.env.MESSAGE_MAINTENANCE.length > 0 && <MaintenanceLayout />}
        </BrowserRouter>
      </div>
    </Suspense>
  );
}
