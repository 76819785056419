import React, { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeStatutJuridique from "./StatutJuridique/ListeStatutJuridique";
import FormNewStatutJuridique from "./StatutJuridique/FormNewStatutJuridique";
import FormUpdateStatutJuridique from "./StatutJuridique/FormUpdateStatutJuridique";
import { createNotification } from "../../config/utils";
import {
  IStatutJuridiquePOST,
  IStatutJuridiquePUT,
} from "../../config/types/referentiel";

export default function StatutJuridique(): React.JSX.Element {
  const store = useContext(StoreContext);
  const [showFormNewStatutJuridique, setShowFormNewStatutJuridique] =
    useState<boolean>(false);
  const [showFormUpdateStatutJuridique, setShowFormUpdateStatutJuridique] =
    useState<boolean>(false);
  const [idstatutjuridiqueToUpdate, setIdstatutjuridiqueToUpdate] = useState<
    number | null
  >(null);

  const handleAddNewStatutJuridique = async (
    statutJuridique: IStatutJuridiquePOST,
  ) => {
    const res = await ReferentielController.insertStatutJuridique(
      statutJuridique,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Statut juridique créé avec succès",
      );
      store.referentiels.setStatutsJuridique([
        ...store.referentiels.statutsJuridique,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du statut juridique",
      );
    }
  };

  const handleUpdateStatutJuridique = async (
    statutJuridique: IStatutJuridiquePUT,
  ) => {
    if (idstatutjuridiqueToUpdate === null) {
      return;
    }

    const res = await ReferentielController.updateStatutJuridique(
      idstatutjuridiqueToUpdate,
      statutJuridique,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Statut juridique modifié avec succès",
      );
      store.referentiels.setStatutsJuridique(
        await ReferentielController.getStatutsJuridique(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du statut juridique",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewStatutJuridique}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewStatutJuridique
          onClickAdd={handleAddNewStatutJuridique}
          onClickClose={() => setShowFormNewStatutJuridique(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateStatutJuridique}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateStatutJuridique
          onClickUpdate={handleUpdateStatutJuridique}
          onClickClose={() => setShowFormUpdateStatutJuridique(false)}
          idstatutjuridique={idstatutjuridiqueToUpdate}
        />
      </Collapse>
      <ListeStatutJuridique
        showFormUpdateStatutJuridique={(idstatutjuridique: number) => {
          setIdstatutjuridiqueToUpdate(idstatutjuridique);
          setShowFormUpdateStatutJuridique(true);
        }}
        onClickNewType={() => setShowFormNewStatutJuridique(true)}
      />
    </>
  );
}
