import { unflatten } from "../../../config/utils";
import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleDefault,
  cellStyleDisabled,
  cellStyleSuccess,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterDate,
  valueFormatterSurface,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Validé",
    field: "idetatvalidationadministrative",
    flex: 1,
    cellStyle: function (params) {
      if (params.data.valide === true) {
        return cellStyleSuccess;
      }
      if (params.value === 0) {
        return cellStyleDisabled;
      }
      if (params.value === 1) {
        return cellStyleDanger;
      }
      return cellStyleWarning;
    },
    cellRenderer: (params) => params.data.libellevalidationadministrative,
  },
  {
    headerName: "Numéro du contrat",
    field: "numerocontrat",
    cellStyle: (params) => {
      if (params.data.abandon) {
        return cellStyleDisabled;
      }
      return cellStyleDefault;
    },
  },
  {
    headerName: "Raison sociale",
    field: "raisonsociale",
  },
  {
    headerName: "Cotisation payée",
    field: "cotisation.0.paye",
    cellStyle: (params) => {
      if (unflatten(params.data).cotisation[0].paye) {
        return cellStyleSuccess;
      }
      return cellStyleDanger;
    },
    cellRenderer: (params) =>
      unflatten(params.data).cotisation[0].paye ? "OUI" : "NON",
  },
  {
    headerName: "Dates",
    children: [
      {
        headerName: "Date de dernière modification",
        field: "datedernieremodification",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
        columnGroupShow: "open",
      },
      {
        headerName: "Date d'envoi de la facture",
        field: "dateenvoifacturecotisation",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
        columnGroupShow: "open",
      },
      {
        headerName: "Date du contrat",
        field: "datecontrat",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
      },
    ],
  },
  {
    headerName: "Production",
    children: [
      {
        headerName: "Organisme stockeur",
        field: "nomorganismestockeur",
      },
      {
        headerName: "Type de production",
        field: "libelletypeproduction",
      },
      {
        headerName: "Nombre de parcelles",
        field: "nbparcelles",
      },
    ],
  },
  {
    headerName: "Surfaces",
    children: [
      {
        headerName: "Surface printemps (ha)",
        field: "surfaceprintemps",
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface automne (ha)",
        field: "surfaceautomne",
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface totale (ha)",
        field: "surfacetotale",
        valueFormatter: valueFormatterSurface,
      },
    ],
  },
  {
    headerName: "Abandon",
    children: [
      {
        headerName: "Abandonné",
        field: "abandon",
        cellStyle: (params) =>
          cellStyleSwitchDefaultBoolean(
            params.value,
            cellStyleDanger,
            cellStyleSuccess,
            cellStyleDefault,
          ),
        cellRenderer: (params) =>
          cellRendererSwitch(params.value, cellRendererDefaultBoolean),
      },
      {
        headerName: "Commentaire abandon",
        field: "commentaireabandon",
      },
    ],
  },
  {
    headerName: "Commentaire",
    field: "commentaire",
    hide: true,
  },
];
