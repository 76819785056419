import React, { useContext, useEffect, useState } from "react";
import { Col, Collapse, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import ExploitationController from "../config/apiUtils/ExploitationController";
import ConventionController from "../config/apiUtils/ConventionController";
import droits from "../config/CodesDroits";
import StoreContext from "../context/StoreContext";
import CotisationsExploitation from "./exploitations/CotisationsExploitation";
import FormNewConvention from "./convention/FormNewConvention";
import ContratsExploitation from "./exploitations/ContratsExploitations";
import ConventionsExploitations from "./exploitations/ConventionsExploitations";
import FicheExploitation from "./exploitations/FicheExploitation";
import FormUpdateExploitation from "./exploitations/FormUpdateExploitation";
import TableSurfacesPotentielles from "./exploitations/TableSurfacesPotentielles";
import {
  createNotification,
  focus,
  getQueryParam,
  GoToTopPage,
  RenderIf,
} from "../config/utils";
import CertificationsIgpExploitations from "./exploitations/CertificationsIgpExploitations";
import FormNewCertificationIgp from "./exploitations/FormNewCertificationIgp";
import RendementsExploitation from "./exploitations/RendementsExploitation";
import { IExploitationPUT } from "../config/types/exploitation";
import { IConventionPOST } from "../config/types/convention";

interface IModalContent {
  title: React.JSX.Element | string;
  message: React.JSX.Element | string;
}

export default function Exploitations(): React.JSX.Element {
  const [showNewCertificationIgpForm, setShowNewCertificationIgpForm] =
    useState<boolean>(false);
  const [showNewConventionForm, setShowNewConventionForm] =
    useState<boolean>(false);
  const [showUpdateExploitationForm, setShowUpdateExploitationForm] =
    useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<IModalContent | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const store = useContext(StoreContext);

  const hideFormUpdateExploitation = () => {
    GoToTopPage();
    setShowUpdateExploitationForm(false);
  };

  const onClickUpdateExploitation = async (
    idexploitation: number,
    exploitation: IExploitationPUT,
  ) => {
    const resExploitation = await ExploitationController.updateExploitation(
      idexploitation,
      exploitation,
      store.millesime.idmillesime,
    );

    if (resExploitation.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Exploitation modifiée avec succès",
      );
      setLoading(true);

      const resDernierDossier =
        await ExploitationController.getDernierDossierUtilisateur();
      store.exploitation.setExploitation(resDernierDossier);

      setLoading(false);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification de l'exploitation...",
      );
      setModal(true);
      setModalContent({
        title: "Erreur de modification",
        message: resExploitation.data.message,
      });
    }
  };

  const onClickSaveNewConvention = async (convention: IConventionPOST) => {
    const resConvention = await ConventionController.insertConvention(
      convention,
    );
    if (resConvention.status === 200) {
      store.conventions.push(resConvention.data);
      setShowNewConventionForm(false);
    }
  };

  const scroll = () => {
    const param = getQueryParam("focus");
    if (param != null) {
      focus(param);
    }
  };

  useEffect(() => {
    scroll();
  }, [getQueryParam("focus")]);

  const renderModal = (): React.JSX.Element => {
    if (modalContent !== null) {
      return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader className="modal-header-form">
            {modalContent.title}
          </ModalHeader>
          <ModalBody className="modal-body-form">
            {modalContent.message}
          </ModalBody>
        </Modal>
      );
    }
    return <></>;
  };

  return (
    <>
      {renderModal()}
      <Row>
        <Col>
          <h2>Exploitation</h2>
        </Col>
      </Row>
      <RenderIf isTrue={!loading}>
        <>
          <RenderIf
            isTrue={store.utilisateur.hasDroits(
              droits.typesdroits.GESTION_EXPLOITATION,
              droits.modalite.lecture,
            )}
          >
            <Row style={{ marginTop: 30 }}>
              <Col>
                <Collapse
                  isOpen={!showUpdateExploitationForm}
                  mountOnEnter
                  unmountOnExit
                >
                  <FicheExploitation
                    onClickUpdate={() => setShowUpdateExploitationForm(true)}
                  />
                </Collapse>

                <Collapse
                  isOpen={showUpdateExploitationForm}
                  mountOnEnter
                  unmountOnExit
                >
                  <FormUpdateExploitation
                    dernierDossier={store.exploitation.informations}
                    zonesProduction={store.exploitation.zonesProduction}
                    onClickUpdateExploitation={onClickUpdateExploitation}
                    hideFormUpdateExploitation={hideFormUpdateExploitation}
                  />
                </Collapse>
              </Col>
            </Row>
          </RenderIf>
          <RenderIf
            isTrue={
              store.utilisateur.hasDroits(
                droits.typesdroits.GESTION_CONVENTION,
                droits.modalite.lecture,
              ) ||
              store.utilisateur.hasDroits(
                droits.typesdroits.GESTION_CONTRAT,
                droits.modalite.lecture,
              )
            }
          >
            <Row>
              <RenderIf
                isTrue={store.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_CONTRAT,
                  droits.modalite.lecture,
                )}
              >
                <Col md="6" style={{ marginTop: 30 }}>
                  <div id="contrat">
                    <ContratsExploitation />
                  </div>
                </Col>
              </RenderIf>
              <RenderIf
                isTrue={store.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_CONVENTION,
                  droits.modalite.lecture,
                )}
              >
                <Col md="6" style={{ marginTop: 30 }}>
                  <Collapse
                    isOpen={!showNewConventionForm}
                    mountOnEnter
                    unmountOnExit
                  >
                    <ConventionsExploitations
                      conventions={store.conventions}
                      onClickNewConvention={() =>
                        setShowNewConventionForm(true)
                      }
                    />
                  </Collapse>
                  <Collapse
                    isOpen={showNewConventionForm}
                    mountOnEnter
                    unmountOnExit
                  >
                    <FormNewConvention
                      millesime={store.millesime.idmillesime}
                      hideFormNewConvention={() =>
                        setShowNewConventionForm(false)
                      }
                      onClickSaveNewConvention={onClickSaveNewConvention}
                      idexploitation={
                        store.exploitation.informations.idexploitation
                      }
                    />
                  </Collapse>
                </Col>
              </RenderIf>
            </Row>
          </RenderIf>
          <RenderIf
            isTrue={store.utilisateur.hasDroits(
              droits.typesdroits.GESTION_CONTRAT,
              droits.modalite.lecture,
            )}
          >
            <Row>
              <RenderIf
                isTrue={store.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_COTISATION,
                  droits.modalite.lecture,
                )}
              >
                <Col md="6" style={{ marginTop: 30 }}>
                  <CotisationsExploitation />
                </Col>
              </RenderIf>
              <Col md="6" style={{ marginTop: 30 }}>
                <div id="intentions">
                  <TableSurfacesPotentielles />
                </div>
              </Col>
            </Row>
          </RenderIf>
          <Row>
            <Col md="6" style={{ marginTop: 30 }}>
              <RendementsExploitation />
            </Col>
            <RenderIf
              isTrue={store.utilisateur.hasDroits(
                droits.typesdroits.GESTION_CERTIFICATION_IGP,
                droits.modalite.lecture,
              )}
            >
              <Col md="6" style={{ marginTop: 30 }}>
                <Collapse
                  isOpen={!showNewCertificationIgpForm}
                  mountOnEnter
                  unmountOnExit
                >
                  <CertificationsIgpExploitations
                    onClickHabilitation={() =>
                      setShowNewCertificationIgpForm(true)
                    }
                  />
                </Collapse>
                <Collapse
                  isOpen={showNewCertificationIgpForm}
                  mountOnEnter
                  unmountOnExit
                >
                  <FormNewCertificationIgp
                    millesime={store.millesime.idmillesime}
                    close={() => setShowNewCertificationIgpForm(false)}
                    onClickSaveNewConvention={onClickSaveNewConvention}
                    idexploitation={
                      store.exploitation.informations.idexploitation
                    }
                  />
                </Collapse>
              </Col>
            </RenderIf>
          </Row>
        </>
      </RenderIf>
    </>
  );
}
