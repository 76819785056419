import { useContext, useEffect, useState } from "react";
import {
  GoToTopPage,
  createNotification,
  RenderIf,
  downloadFile,
} from "../../config/utils";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { columns } from "../../components/Grids/Contrat/tableparcelle";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ContratController from "../../config/apiUtils/ContratController";
import CotisationEditionController from "../../config/apiUtils/CotisationEditionController";
import PlanProductionEditionController from "../../config/apiUtils/PlanProductionEditionController";
import useConstructor from "../../config/hooks/useConstructor";
import { columns as columnsCotisation } from "../../components/Grids/Contrat/tablecotisation";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import Constantes from "../../config/constantes";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import PdfButton from "../../components/Buttons/PdfButton";
import EditionController from "../../config/apiUtils/EditionController";
import AgGrid from "../../components/AgGrid";
import { getColumnsByField } from "../../config/gridUtils";

export default function FicheContrat(props) {
  const store = useContext(StoreContext);
  const [contrat, setContrat] = useState(null);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModalNewAnnexe, setShowModalNewAnnexe] = useState(false);

  const loadDetailContrat = async () => {
    setLoading(true);
    const resContrat = await ContratController.getContratDetail(
      props.idcontrat,
    );
    resContrat.cotisation.forEach((cot) => {
      cot.montantpayettc = cot.montantpaye * 1.2;
      cot.resteapayer = (cot.prixcotisation - cot.montantpaye) * 1.2;
    });
    setContrat(resContrat);
    const typesSol = [];
    resContrat.parcelles.forEach((p) => {
      if (!typesSol.some((type) => type === p.libelletypesol)) {
        typesSol.push(p.libelletypesol);
      }
    });
    getColumnsByField(columns, "libellevariete").cellEditorParams.values = (
      await ReferentielController.getVarietes(true)
    ).map((variete) => {
      return {
        value: variete.idrefvariete,
        label: variete.libelle,
      };
    });
    if (
      resContrat?.idetatvalidationadministrative ==
      Constantes.etatsValidationAdministrative.nonValide
    ) {
      getColumnsByField(columns, "surfaceengageeprintemps").editable = true;
      getColumnsByField(columns, "surfaceengageeautomne").editable = true;
      getColumnsByField(columns, "surfaceprintemps").editable = false;
      getColumnsByField(columns, "surfaceautomne").editable = false;
    } else {
      getColumnsByField(columns, "surfaceengageeprintemps").editable = false;
      getColumnsByField(columns, "surfaceengageeautomne").editable = false;
      getColumnsByField(columns, "surfaceprintemps").editable = true;
      getColumnsByField(columns, "surfaceautomne").editable = true;
    }
    setLoading(false);
  };

  useEffect(() => {
    if (props.reloadDetail) {
      loadDetailContrat();
    }
    props.setReloadDetail(false);
  }, [props.reloadDetail]);

  const onEditCompleteCotisation = async (params) => {
    var res = null;
    switch (params.colDef.field) {
      case "montantpayettc":
        res = await CotisationEditionController.updateMontantpayettc(
          params.data.idcotisation,
          params.newValue,
        );
        break;
      case "paye":
        res = await CotisationEditionController.updatePaye(
          params.data.idcotisation,
          params.newValue,
        );
        break;
      case "datepaye":
        res = await CotisationEditionController.updateDatePaye(
          params.data.idcotisation,
          params.newValue,
        );
        break;
      case "penalitepayee":
        res = await CotisationEditionController.updatePenalitePayee(
          params.data.idcotisation,
          params.newValue,
        );
        break;
      default:
        return;
    }

    if (res.status === 200) {
      createNotification("success", "Succès", res.data.message);
      setLoading(true);
      await loadDetailContrat();
      const tmpContrat = { ...contrat };

      tmpContrat.cotisation.forEach((c) => {
        if (c.idcotisation === params.data.idcotisation) {
          if (params.colDef.field === "montantpayettc") {
            c.montantpayettc = parseFloat(params.newValue);
            c.resteapayer =
              parseFloat(params.data.prixcotisation) * 1.2 - params.newValue;
          } else if (params.colDef.field === "paye") {
            c.paye = params.newValue;
            c.montantpayettc = params.newValue
              ? parseFloat(c.prixcotisation * 1.2)
              : parseFloat(c.montantpayettc);
            c.resteapayer = params.newValue
              ? 0
              : parseFloat(params.data.prixcotisation) * 1.2 - c.montantpayettc;
          } else if (params.colDef.field === "datepaye") {
            c.datepaye = params.newValue;
          } else if (params.colDef.field === "penalitepayee") {
            c.penalitepayee = params.newValue;
          }
        }
      });

      setContrat(tmpContrat);
      setLoading(false);
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  const getSurfaceReellePrintemps = () => {
    var printemps = 0.0;
    contrat?.parcelles.forEach(
      (parcelle) => (printemps += parseFloat(parcelle.surfaceprintemps ?? 0)),
    );
    return printemps.toFixed(2);
  };

  const getSurfaceReelleAutomne = () => {
    var automne = 0.0;
    contrat?.parcelles.forEach(
      (parcelle) => (automne += parseFloat(parcelle.surfaceautomne ?? 0)),
    );
    return automne.toFixed(2);
  };

  const getSurfaceReelleTotal = () => {
    var total = 0.0;
    contrat?.parcelles.forEach(
      (parcelle) =>
        (total +=
          parseFloat(parcelle.surfaceautomne ?? 0) +
          parseFloat(parcelle.surfaceprintemps ?? 0)),
    );
    return total.toFixed(2);
  };

  const onEditComplete = async (params) => {
    const res = await PlanProductionEditionController.updateField(
      params.data.idplanproduction,
      params.colDef.field === "libellevariete"
        ? "idrefvariete"
        : params.colDef.field,
      params.newValue,
    );
    if (res.status === 200) {
      await loadDetailContrat();
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  useConstructor(async () => {
    await loadDetailContrat();
  });

  const onSubmitNewAnnexe = async (event) => {
    event.preventDefault();
    const res = await ContratController.addNewAnnexeOld(
      contrat.idcontrat,
      event.target.nbparcelles.value,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Nouvelle annexe de " +
          event.target.nbparcelles.value +
          " parcelles créée !",
      );
      await loadDetailContrat();
      setShowModalNewAnnexe(false);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de l'ajout de l'annexe...",
      );
    }
  };

  const renderModalFormAddAnnexe = () => {
    return (
      <Modal
        isOpen={showModalNewAnnexe}
        toggle={() => setShowModalNewAnnexe(!showModalNewAnnexe)}
        autoFocus={false}
      >
        <ModalHeader className="modal-header-form">Ajout d'annexe</ModalHeader>
        <Form onSubmit={onSubmitNewAnnexe}>
          <ModalBody className="modal-body-form">
            <Label for="nbparcelles">
              Combien de lignes y a-t-il dans cette annexe ?
            </Label>
            <Input
              autoFocus={true}
              name="nbparcelles"
              type="number"
              placeholder="Combien de lignes y a-t-il dans cette annexe ?"
            />
          </ModalBody>
          <ModalFooter className="modal-footer-form">
            <ValidateButton />
            <CancelButton onClick={() => setShowModalNewAnnexe(false)} />
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const renderTableInfoContrat = () => {
    return (
      <Table>
        <tbody>
          <tr>
            <th scope="row">Statut</th>
            <td>
              <RenderIf isTrue={contrat?.abandon}>
                <Alert fade={false} color="danger" style={styles.alertStyle}>
                  ABANDON
                </Alert>
                {contrat?.commentaireabandon}
              </RenderIf>
              <RenderIf isTrue={!contrat?.abandon}>
                <Alert fade={false} color="success" style={styles.alertStyle}>
                  EN COURS
                </Alert>
              </RenderIf>
            </td>
          </tr>
          <tr>
            <th scope="row">Numéro de contrat</th>
            <td>{contrat?.numerocontrat ?? "Non renseigné"}</td>
          </tr>
          <tr>
            <th scope="row">Date du contrat</th>
            <td>{contrat?.datecontrat}</td>
          </tr>
          <tr>
            <th scope="row">Date de dernière modification</th>
            <td>{contrat?.datedernieremodification ?? "-"}</td>
          </tr>
          <tr>
            <th scope="row">Date d'envoi facture cotisation</th>
            <td>{contrat?.dateenvoifacturecotisation ?? "-"}</td>
          </tr>
          <tr>
            <th scope="row">Date d'envoi facture de pénalité</th>
            <td>{contrat?.dateenvoifacturerelance ?? "-"}</td>
          </tr>
          <tr>
            <th scope="row">Date de réception de la traçabilité</th>
            <td>{contrat?.datereceptiontracabilite ?? "-"}</td>
          </tr>
          <tr>
            <th scope="row">Exploitation</th>
            <td>{contrat?.raisonsociale}</td>
          </tr>
          <tr>
            <th scope="row">Annexes au contrat</th>
            <td>{contrat?.nbannexes}</td>
          </tr>
          <tr>
            <th scope="row">Etat du contrat</th>
            <td>
              {contrat?.nbparcellesrenseignee === contrat?.nbparcelles ? (
                <>
                  <Alert fade={false} color="success" style={styles.alertStyle}>
                    COMPLET
                  </Alert>
                  {contrat?.nbparcellesrenseignee} parcelle(s) saisie
                </>
              ) : contrat?.nbparcellesrenseignee < contrat?.nbparcelles ? (
                <>
                  <Alert fade={false} color="danger" style={styles.alertStyle}>
                    INCOMPLET
                  </Alert>
                  Il reste{" "}
                  {contrat?.nbparcelles - contrat?.nbparcellesrenseignee}{" "}
                  parcelle(s) à saisir pour ce contrat...
                </>
              ) : (
                <>
                  <Alert fade={false} color="danger" style={styles.alertStyle}>
                    ERREUR
                  </Alert>
                  Il y a {contrat?.nbparcellesrenseignee - contrat?.nbparcelles}{" "}
                  parcelle(s) en trop sur ce contrat...
                </>
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">Organisme stockeur</th>
            <td>{contrat?.nomorganismestockeur ?? "Non renseigné"}</td>
          </tr>
          <tr>
            <th scope="row">Type de production</th>
            <td>{contrat?.libelletypeproduction}</td>
          </tr>
          <tr>
            <th scope="row">Validé administrativement</th>
            <td>
              <Alert
                fade={false}
                color={
                  contrat?.valide
                    ? "success"
                    : contrat?.idetatvalidationadministrative ==
                      Constantes.etatsValidationAdministrative.nonValide
                    ? "danger"
                    : "warning"
                }
                style={styles.alertStyleValidation}
              >
                {contrat?.libellevalidationadministrative}
              </Alert>
            </td>
          </tr>
          <tr>
            <th scope="row">Signature</th>
            <td>
              <Row style={{ margin: 0 }}>
                <Alert
                  fade={false}
                  color={contrat?.signee ? "success" : "danger"}
                  style={styles.alertStyle}
                >
                  {contrat?.signee ? "OUI" : "NON"}
                </Alert>
                <RenderIf
                  isTrue={
                    contrat?.nbparcellesrenseignee === contrat?.nbparcelles &&
                    !contrat?.signee
                  }
                >
                  <Button
                    color="danger"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setModal(true);
                    }}
                  >
                    Signer le contrat
                  </Button>
                </RenderIf>
              </Row>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const onClickSignerContrat = async () => {
    const res = await ContratController.signerContrat(props.idcontrat);
    if (res.status === 200) {
      createNotification(
        "success",
        "Contrat complété et signé",
        "Votre contrat a bien été signé, il est maintenant en attente d'être validé par votre Organisme Stockeur",
        15000,
      );
      await loadDetailContrat();
      setModal(false);
    }
  };

  const onCellClicked = (params) => {
    if (params.colDef.field === "visualiser") {
      //console.log("params", params);
      //console.log(params.colDef.field === "visualiser");
    }
  };

  const renderModalSignatureContrat = () => {
    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader className="modal-header-form">
          Signature du contrat {contrat?.numerocontrat}
        </ModalHeader>
        <ModalBody className="modal-body-form">
          En signant le contrat, vous certifiez avoir pris connaissance des
          engagements vis-à-vis de la filière, et vous engagez à les respecter.
          <br />
          <br />
          Confirmez-vous la signature du contrat {contrat?.numerocontrat} ?
        </ModalBody>
        <ModalFooter className="modal-footer-form">
          <Button
            color="primary"
            autoFocus={true}
            onClick={onClickSignerContrat}
          >
            Signer le contrat
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(!modal)}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>
                Fiche contrat :{" "}
                {contrat?.numerocontrat ?? contrat?.raisonsociale}
              </CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <PdfButton
                color="primary"
                style={{ marginRight: 30 }}
                onClick={async () => {
                  createNotification(
                    "info",
                    "",
                    "Préparation du document en cours...",
                  );
                  const resDocument = await EditionController.getContrat(
                    props.idcontrat,
                  );
                  downloadFile(
                    resDocument,
                    `contrat_${contrat.millesime}-${contrat.numerocontrat}.pdf`,
                    "application/octet-stream",
                  );
                  createNotification(
                    "success",
                    "",
                    "Contrat généré avec succès.",
                  );
                }}
              />
              <CloseButton onClick={props.hideFicheContrat} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {renderModalFormAddAnnexe()}
          {renderModalSignatureContrat()}
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Row style={{ marginTop: 30 }}>
              <Col md="5">{renderTableInfoContrat()}</Col>
              <Col md="7">
                <Row>
                  <Col md="6">
                    <Table>
                      <tbody>
                        <tr>
                          <th scope="row">Surfaces réelles</th>
                          <td>
                            PRINTEMPS : {getSurfaceReellePrintemps()} ha
                            <br />
                            AUTOMNE : {getSurfaceReelleAutomne()} ha
                            <br />
                            TOTAL : {getSurfaceReelleTotal()} ha
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md="6">
                    <Table style={{ color: "#FFFFFF" }}>
                      <tbody>
                        <tr>
                          <th scope="row">Surfaces engagées</th>
                          <td>
                            PRINTEMPS : {contrat?.surfaceprintemps} ha
                            <br />
                            AUTOMNE : {contrat?.surfaceautomne} ha
                            <br />
                            TOTAL : {contrat?.surfacetotale} ha
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <h5>Suivi des cotisations</h5>
                <AgGrid
                  columnDefs={columnsCotisation}
                  rowData={contrat?.cotisation}
                  onCellEditingStopped={onEditCompleteCotisation}
                  height={400}
                />
                <RenderIf
                  isTrue={
                    contrat?.commentaire != null && contrat?.commentaire != ""
                  }
                >
                  <Table style={{ marginTop: 10, color: "#FFFFFF" }}>
                    <tbody>
                      <tr>
                        <th scope="row">Commentaire</th>
                        <td>{contrat?.commentaire}</td>
                      </tr>
                    </tbody>
                  </Table>
                </RenderIf>
              </Col>
            </Row>
            <Row style={{ marginBottom: 30, marginTop: 10 }}>
              <h5>Parcelles contractualisées</h5>
              <Col>
                <AgGrid
                  columnDefs={columns}
                  onCellClicked={onCellClicked}
                  onCellEditingStopped={onEditComplete}
                  rowData={contrat?.parcelles}
                  showFilterLine
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RenderIf isTrue={!store.utilisateur.informations.isProducteur}>
                  <Button
                    color="primary"
                    style={{ marginRight: 10 }}
                    onClick={props.onClickUpdateContrat}
                  >
                    Modifier
                  </Button>
                </RenderIf>
                <Button
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    if (store.millesime.idmillesime <= 2023) {
                      setShowModalNewAnnexe(true);
                    } else {
                      props.onClickNewAnnexe();
                    }
                    GoToTopPage();
                  }}
                >
                  Ajouter une annexe
                </Button>
                <RenderIf
                  isTrue={
                    store.millesime.idmillesime >= 2024 &&
                    contrat?.nbannexes > 0
                  }
                >
                  <Button
                    color="primary"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      props.onClickShowAnnexe();
                      GoToTopPage();
                    }}
                  >
                    Voir les annexes
                  </Button>
                </RenderIf>
                <RenderIf
                  isTrue={contrat?.nbparcellesrenseignee < contrat?.nbparcelles}
                >
                  <Button
                    color="primary"
                    style={{ marginRight: 10 }}
                    onClick={props.onClickCompleterContrat}
                  >
                    Compléter le contrat
                  </Button>
                </RenderIf>
                <RenderIf
                  isTrue={
                    contrat?.nbparcellesrenseignee > contrat?.nbparcelles ||
                    (contrat?.idetatvalidationadministrative !==
                      Constantes.etatsValidationAdministrative.valideOS &&
                      contrat?.idetatvalidationadministrative !==
                        Constantes.etatsValidationAdministrative.valide &&
                      contrat?.nbparcellesrenseignee > 0)
                  }
                >
                  <Button
                    color="primary"
                    style={{ marginRight: 10 }}
                    onClick={props.onClickDeleteParcelle}
                  >
                    Retirer des parcelles
                  </Button>
                </RenderIf>
              </Col>
            </Row>
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
}

const styles = {
  alertStyle: {
    height: 12,
    width: "100%",
    padding: "0rem 1rem 1.5rem",
    display: "flex",
    justifyContent: "center",
    marginBottom: "0.5rem",
  },
  alertStyleValidation: {
    width: "100%",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    marginBottom: "0.5rem",
  },
};
